import style from "../css/Home.module.css";
import vonage_logo from "../assets/Vonage_Logo_with_title.png";
import AppStatus from "./AppStatus";
import useHttp from "../hooks/useHttp";
import * as React from 'react';
import { api } from "../constants/backend";
import { AppStatusResponse } from "../dto/appStatusResponse";
export default function Home() {
  const { isLoading, error, sendRequest } = useHttp();
  const [appStatus, updateAppStatus]=React.useState([]);
  const [allAppUp, updateAllAppUp]=React.useState(true);
  const [overAllStatusMsg, updateOverAllStatusMsg]=React.useState("All Application Operational");
  React.useEffect(()=>{
    fetchData();
    setInterval(fetchData,1000000);
  },[])
  const transformGetAppStatusHttpData=(data)=>{
    const appResponse=new AppStatusResponse(data)
    updateAppStatus(appResponse.appStatusList);
    let downAppCount=appResponse.appStatusList.filter(data=>data.status!=="OPERATIONAL").length
    if(downAppCount>0)
    {
      updateAllAppUp(false)
      updateOverAllStatusMsg(`${downAppCount} out of ${appResponse.appStatusList.length} Applications is facing outage`)
    }
    else
    {
      updateAllAppUp(true)
      updateOverAllStatusMsg("All Application Operational")
    }
  }

  const fetchData=()=>{
    sendRequest(
      {
        url: api.getStatus.apiName
      },
      transformGetAppStatusHttpData)
  }
  return (
    <>
      <div className={style["layout"]}>
        <div className={style["layout-header"]}>
          <div>
            <img src={vonage_logo} alt="vonage logo" />
          </div>
          <div className={style["layout-header-child"]}>
            Carrier Automation
          </div>
        </div>
        <div className={`${style["overall-status"]} ${style[allAppUp?"overall-status-all-app-up":"overall-status-all-app-down"]}`}>
          <div className={style["text"]}>{overAllStatusMsg}</div>
        </div>
        <div className={style["time-zone"]}>Time Zone: GMT</div>
        <div className={style["content"]}>
          {appStatus.map((data) => (
            <>
              <AppStatus appDetail={data} />
              <div className={style["content-divider"]}></div>
            </>
          ))}
        </div>
      </div>
    </>
  );
}
