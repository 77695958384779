import style from "../css/AppStatus.module.css";
import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
const formatDate=(date)=>{
  return date.toLocaleDateString("en-us", { year: 'numeric', month: 'short', day: 'numeric' });
}
const getStatusTemplate = (downTime) => {
  let temp = {};
  for (let i = 0; i < 90; i++) {
    let date=new Date(new Date().getTime() - 86400000 * (89-i))
    temp[formatDate(date)] = (
      <div className={`${style["day-app-status-bar"]} ${style["day-app-status-up"]}`} key={i}>
        <span className={style["day-app-status-tooltip"]}>
          <div className={style["day-stat"]}>
            <div className={style["day-stat-header"]}>
              {formatDate(date)}
            </div>
            <div className={style["day-stat-content"]}>
              No downtime recorded on this day.
            </div>
          </div>
        </span>
      </div>
    );
  }
  for(let i=0;i<downTime.length;i++)
  {
    if(temp[formatDate(new Date(downTime[i].date))])
    {
      let downTimeInMin=  Math.floor(downTime[i].duration/(1000*60))
      let timeHour=Math.floor(downTimeInMin/60);
      let timeMin=downTimeInMin-(timeHour*60);
      let statColor="day-app-status-partial-outage-low"
      if(timeHour >=1 && timeHour<3)
      {
        statColor="day-app-status-partial-outage-medium"
      }
      if(timeHour >=3 && timeHour<7)
      {
        statColor="day-app-status-partial-outage-high"
      }
      else if(timeHour>=7)
      {
        statColor="day-app-status-down"
      }
      temp[formatDate(new Date(downTime[i].date))] = (
        <div className={`${style["day-app-status-bar"]} ${style[statColor]}`} key={i}>
          <span className={style["day-app-status-tooltip"]}>
            <div className={style["day-stat"]}>
              <div className={style["day-stat-header"]}>
                {formatDate(new Date(downTime[i].date))}
              </div>
              <div className={`${style["day-stat-content"]} ${style["day-stat-content-alert"]}`}>
              <FontAwesomeIcon className={style["alert-icon"]} icon={faExclamationTriangle} />
               { `Outage for ${timeHour} hr ${timeMin} min.`}
              </div>
              <div className={style["day-stat-content-data"]} >
              <ul>
                {downTime[i].reason.map(data=><li>{data}</li>)}
              </ul>  
              </div>
            </div>
          </span>
        </div>
      );
    }
  }
  return temp;
};

export default function AppStatus(props) {
  const [stat, updateStat]=useState(getStatusTemplate(props.appDetail.downTime));
  const totalDownTime=()=>{
    let downTimeInHour=  (props.appDetail.downTime.reduce((prevValue, currentValue)=>prevValue+currentValue.duration,0))/(1000*60*60);
    return downTimeInHour
  }
  const upTimeRate=(100-totalDownTime()*100/2190).toFixed(3)
  return (
    <>
      <div className={style["layout"]}>
        <div className={style["header"]}>
          <div className={style["header-title"]}>{props.appDetail.appName}</div>
          <div className={`${style["header-status"]} ${style[props.appDetail.status==="OPERATIONAL"?"up":props.appDetail.status==="COMPLETE_OUTAGE"?"down":"partialdown"]}`}>
            {props.appDetail.status.replace("_"," ")}
          </div>
        </div>
        <div className={`${style["day-app-status-wrapper"]}`}>
        {Object.keys(stat).map(key => stat[key])}
        </div>
        <div className={style["footer"]}>
          <div>90 days ago</div>
          <div className={style["footer-line"]}></div>
          <div>{`${upTimeRate}% uptime`}</div>
          <div className={style["footer-line"]}></div>
          <div>Today</div>
        </div>
      </div>
    </>
  );
}