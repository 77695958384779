export class AppStatusResponse{
    appStatusList=[];
    constructor(data) {
      this.appStatusList = data.appStatusList.map((appStatus) => new AppStatus(appStatus));
    }
}

class AppStatus{
    appName;
    status;
    faultReason;
    downTime=[];
    constructor(data) {
        this.appName = data.appName;
        this.status = data.status;
        this.faultReason = data.faultReason;
        this.downTime=data.downTime.map((data) => new DownTime(data));
      }
}

class DownTime{
  date;
  duration;
  reason=[];
  constructor(data) {
    this.date = data.date;
    this.duration = data.duration;
    this.reason = data.reason;
  }
}